import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rxjs';

import { User } from '../models';
import { jhtAuthEndpoints, tokenParams } from '../../../auth.config';
import { PlatformService } from './platform.service';
import { DataUpdateRunner } from '../data/data-update-runner';
import { ActiveUserService } from './active-user.service';
import { StorageService } from './storage/storage.service';

import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';
import { environment } from '../../environments/environment';

export interface MeaAuthUsersResponse {
    status: string;
    message: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    iosOrAndroid: boolean;
    metadata: Record<string, unknown> = {};

    constructor(
        private http: HttpClient,
        private platformService: PlatformService,
        private dataUpdater: DataUpdateRunner,
        private activeUserService: ActiveUserService,
        private storage: StorageService
    ) {
        this.iosOrAndroid = this.platformService.isNativeDevice;
    }

    async login(username: string, password: string): Promise<any> {
        tokenParams.username = username;
        tokenParams.password = password;

        const accessData: Record<string, any> = await lastValueFrom(
            this.http.post(encodeURI(`${jhtAuthEndpoints.token}`), tokenParams)
        );

        if (accessData.error) {
            return new Error(accessData.error);
        }

        const headers: Record<string, Record<string, string>> = {
            headers: { authorization: `Bearer ${accessData.access_token}` },
        };

        const userSubId: string = await lastValueFrom(
            this.http.get(encodeURI(`${jhtAuthEndpoints.userinfo}`), headers)
        ).then((info: any) => info.sub);

        const userProfile = await lastValueFrom(
            this.http.get(
                encodeURI(`${jhtAuthEndpoints.users}${userSubId}`),
                headers
            )
        ).then(async (profile: User) => {
            await this.activeUserService.setUser(profile, accessData);

            if (this.platformService.isNativeDevice) {
                await this.dataUpdater.next({
                    userProfile: await this.activeUserService.userProfile(),
                });
            }

            return profile;
        });
        return userProfile;
    }

    async logout() {
        await this.activeUserService.removeUser();

        this.dataUpdater.resetUpdateState();

        window.location.reload();
    }

    async isAuthenticated(): Promise<boolean> {
        try {
            const [expiresAt, userData] = await Promise.all([
                this.storage
                    .get('expires_at')
                    .then((expAt) => (expAt ? +expAt : null)),
                this.activeUserService.userProfile(),
            ]);

            // Check whether the current time is past the access token's expiry time
            const authenticated = userData && new Date().getTime() < expiresAt;
            if (!authenticated && userData) {
                this.logout();
            }
            return !!authenticated;
        } catch (_) {
            return false;
        }
    }

    async getDataFromAuthEndpoint(email: string): Promise<MeaAuthUsersResponse> {
        const options: HttpOptions = {
            url: `${environment.apiUrl}/meaAuthUsers`,
            params: { email },
        };
        const response: HttpResponse = await CapacitorHttp.get(options);

        return response.data;
    }
}
