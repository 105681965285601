/* eslint-disable @typescript-eslint/naming-convention */
import { isPlatform } from '@ionic/angular';

import config from 'capacitor.config.json';

const domain = 'jht.auth0.com';
const clientId = 'tilekWmyEdKXKzzzwggSG3kTlYESJxbl';
const realm = 'MEA2';
const scope = 'openid profile';
const passwordRealmEndpoint = 'http://auth0.com/oauth/grant-type/password-realm';
const { appId } = config;
const auth0Domain = domain;
const iosOrAndroid = isPlatform('ios') || isPlatform('android');
const audience = 'https://jht.auth0.com/api/v2/';
const callbackUri = iosOrAndroid
  ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  : 'http://localhost:4200/';

export const authConfig = {
  domain,
  clientId,
  audience,
  redirectUri: callbackUri,
  scope,
  realm
};

export const tokenParams = {
  client_id: clientId,
  grant_type: passwordRealmEndpoint,
  realm: authConfig.realm,
  scope: authConfig.scope,
  audience,
  username: '',
  password: ''
};

export const jhtAuthEndpoints = {
  domain:   `https://${domain}`,
  token: `https://${domain}/oauth/token`,
  users: `${audience}users/`,
  userinfo: `https://${domain}/userinfo`
};




