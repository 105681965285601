import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@jht/brand-translate';

import { FileModule } from './../../file/file.module';

import { NavigationCardComponent } from './navigation-card.component';

@NgModule({
    declarations: [NavigationCardComponent],
    imports: [CommonModule, FileModule, RouterModule, IonicModule, TranslateModule],
    exports: [NavigationCardComponent],
})
export class NavigationCardModule {}
