import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { User, regionMap } from '../models';
import { StorageService } from './storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ActiveUserService {
    currentUser$: Observable<User>;

    private currentUserSubject$ = new BehaviorSubject<User>(null);

    constructor(
        private storage: StorageService
    ) {
        this.currentUser$ = this.currentUserSubject$.asObservable();

        this.userProfile().then((user) => {
          if(user){
            user.user_metadata.region = regionMap(user.user_metadata.region);
            this.currentUserSubject$.next(user);
          }
        });
    }

    get currentUserValue(): User {
        return this.currentUserSubject$.value;
    }

    async userProfile(): Promise<User> {
        const userData = await this.storage.get('userData');
        try {
            return JSON.parse(userData);
        } catch (_) {
            return null;
        }
    }

    setUser(userProfile: User, accessData: any): Promise<void> {
        this.currentUserSubject$.next(userProfile);
        return this.setSession(accessData, userProfile);
    }

    removeUser(): Promise<void> {
        this.currentUserSubject$.next(null);
        return this.unsetSession();
    }

    private async setSession(authResult, profile: User): Promise<void> {
        const numWeeks = 1;
        const now = new Date();
        const weekFromNow = now.setDate(now.getDate() + numWeeks * 7);
        const expiresAt = JSON.stringify(
            authResult.expires_in * 100 + new Date(weekFromNow).getTime()
        );

        await Promise.all([
            this.storage.set('expires_at', expiresAt),
            this.storage.set('userData', JSON.stringify(profile))
        ]);
    }

    private async unsetSession(): Promise<void> {
        await Promise.all([
            this.storage.remove('expires_at'),
            this.storage.remove('userData')
        ]);
    }
}
