import { Injectable, Injector } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import {
    Encoding,
    Filesystem,
    Directory,
    WriteFileOptions,
} from '@capacitor/filesystem';

import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { TranslateService } from '@jht/brand-translate';
import { BehaviorSubject, Subject, filter } from 'rxjs';

import { environment } from 'src/environments/environment';
import { APP_STATE_KEYS, regionMap } from 'src/app/models';
import { PermissionService } from 'src/app/services/permission.service';
import { StateService } from 'src/app/services/state.service';
import { DataUpdater } from 'src/app/data/data-updater';
import { MediaSyncUpdater } from 'src/app/data/media-sync-updater';
import { DataService } from 'src/app/services/data.service';
import { isAppUpdateAvailable } from './app-update-checker';
import { PlatformService } from '../services/platform.service';
import { DataUpdateComponent, DataUpdateComponentOptions } from './data-update/data-update.component';
import { MediaListService } from './media-list.service';

export interface DataUpdateRunnerOptions {
    userProfile: any;
}

@Injectable({
    providedIn: 'root',
})
export class DataUpdateRunner {
    env: string;
    transalationService: TranslateService;
    mediaProgress$: BehaviorSubject<number>;

    constructor(
        private backgroundMode: BackgroundMode,
        private permissionService: PermissionService,
        private loadingControl: LoadingController,
        private stateService: StateService,
        private injector: Injector,
        private dataService: DataService,
        private platformService: PlatformService,
        private modalController: ModalController,
        private mediaListService: MediaListService
    ) {
        this.dataService.translationsData$.pipe(filter(d => !!Object.keys(d).length)).subscribe((_) => {
            this.transalationService = this.injector.get(
                TranslateService
            ) as TranslateService;
        });

        this.env = environment.name;
    }

    /**
     * Write a file to Directory.Data to the `path` path. Overwrites the file of the same name, if any.
     */
    static async writeFile(
        path: string,
        data: string,
        encodeUtf8?: boolean
    ): Promise<void> {
        try {
            const writeFileOptions: WriteFileOptions = {
                path,
                data,
                directory: Directory.Data,
                recursive: true,
            };

            if (encodeUtf8) {
                writeFileOptions.encoding = Encoding.UTF8;
            }

            await Filesystem.writeFile(writeFileOptions);
        } catch (err) {
            // TODO Display message to user saying data couldn't be updated
            console.log('Uncaught err: ', err);
        }
    }

    async next(options: DataUpdateRunnerOptions) {
        const toBool = (str: string) => str === 'true';
        const userRegion = options.userProfile.user_metadata.region;

        const [
            dataSyncStarted,
            dataSyncContentCompleted,
            dataSyncCompleted,
            dataLastChecked,
            dataLastUpdated,
        ] = await Promise.all([
            this.stateService
                .get(APP_STATE_KEYS.DATA_SYNC_STARTED)
                .then(toBool),
            this.stateService
                .get(APP_STATE_KEYS.DATA_SYNC_CONTENT_COMPLETED)
                .then(toBool),
            this.stateService
                .get(APP_STATE_KEYS.DATA_SYNC_COMPLETED)
                .then(toBool),
            this.stateService.get(APP_STATE_KEYS.DATA_LAST_CHECKED)
                .then((date: string) => date ? new Date(date) : null),
            this.stateService.get(APP_STATE_KEYS.DATA_LAST_UPDATED)
                .then((date: string) => date ? new Date(date) : null),
        ]);

        if (!dataSyncStarted) {
            return this.update(options, true, true);
        }

        if (dataSyncStarted && !dataSyncContentCompleted) {
            return this.update(options, true, true);
        }

        if (dataSyncStarted && dataSyncContentCompleted && !dataSyncCompleted) {
            return this.update(options, false, true);
        }

        // Check for both app and content updates
        if (dataLastChecked.getUTCDay() !== new Date().getUTCDay()) {
            const appIsOutdated = await this.isAppOutdated();
            this.stateService.set(APP_STATE_KEYS.APP_IS_OUTDATED, appIsOutdated.toString());

            const isOutdated = await this.isDataOutdated(dataLastUpdated, userRegion);

            if (isOutdated) {
                this.stateService.set(APP_STATE_KEYS.DATA_IS_OUTDATED, 'true');
            }
        }

        if (dataSyncStarted && dataSyncContentCompleted && dataSyncCompleted) {
            return Promise.resolve();
        }

        return Promise.resolve();
    }

    async resetUpdateState() {
        await this.stateService.set(APP_STATE_KEYS.DATA_SYNC_STARTED, 'false');
        await this.stateService.set(
            APP_STATE_KEYS.DATA_SYNC_CONTENT_COMPLETED,
            'false'
        );
        await this.stateService.set(
            APP_STATE_KEYS.DATA_SYNC_COMPLETED,
            'false'
        );
    }

    async setStateStarted() {
        await this.stateService.set(APP_STATE_KEYS.DATA_SYNC_STARTED, 'true');
        await this.stateService.set(
            APP_STATE_KEYS.DATA_SYNC_CONTENT_COMPLETED,
            'false'
        );
        await this.stateService.set(
            APP_STATE_KEYS.DATA_SYNC_COMPLETED,
            'false'
        );
    }

    async setContentStateFinished() {
        await this.stateService.set(
            APP_STATE_KEYS.DATA_SYNC_CONTENT_COMPLETED,
            'true'
        );
    }

    async setStateFinished() {
        await this.stateService.set(APP_STATE_KEYS.DATA_SYNC_COMPLETED, 'true');
        const now = new Date().toUTCString();
        await this.stateService.set(APP_STATE_KEYS.DATA_LAST_CHECKED, now);
        await this.stateService.set(APP_STATE_KEYS.DATA_LAST_UPDATED, now);
        await this.stateService.set(APP_STATE_KEYS.DATA_IS_OUTDATED, 'false');
    }

    async update(options: DataUpdateRunnerOptions, data: boolean, media: boolean): Promise<void> {
        if (!data && !media) {
            return;
        }

        this.backgroundMode.enable();

        const userRegion = regionMap(options.userProfile.user_metadata.region);
        const options$ = new BehaviorSubject<DataUpdateComponentOptions>(null);
        let loading: HTMLIonModalElement = null;
        let dismissable = false;

        const createModal = async (): Promise<HTMLIonModalElement> =>
            this.modalController.create({
                component: DataUpdateComponent,
                componentProps: { options$ },
                canDismiss: (): Promise<boolean> => Promise.resolve(dismissable)
            });

        const cleanup = async () => {
            dismissable = true;
            options$.complete();

            if (this.mediaProgress$) {
                this.mediaProgress$.complete();
            }

            if (loading) {
                await loading.dismiss();
                loading.remove();
            }

            this.backgroundMode.disable();
            this.backgroundMode.isScreenOff((isScreenOff) => {
                if (isScreenOff) {
                    this.backgroundMode.wakeUp();
                }
            });
        };

        if (data) {
            await this.setStateStarted();

            await this.permissionService.checkAndRequestPermission();

            options$.next({
                message: this.transalationService.instant('LANDING-PAGE_NATIVE_CONTENT-MODAL_TEXT'),
                progress$: new Subject(),
                dataType: 'data',
            });

            loading = await createModal();
            await loading.present();

            try {
                await this.updateData(userRegion);
                await this.setContentStateFinished();
            } catch (e) {
                console.log('Data update failed: ', e);
                await cleanup();
                return;
            }
        }

        if (media) {
            this.mediaProgress$ = new BehaviorSubject(0);

            options$.next({
                message: this.transalationService.instant('LANDING-PAGE_NATIVE_ASSET-MODAL_TEXT'),
                dataType: 'media',
                progress$: this.mediaProgress$,
            });

            if (!loading) {
                loading = await createModal();
                await loading.present();
            }

            try {
                await this.updateMedia(userRegion);
            } catch (e) {
                console.log('Media sync failed: ', e);
                await cleanup();
                return;
            }
        }

        await this.setStateFinished();

        await cleanup();
    }

    private async updateData(userRegion) {
        const dataUpdater = new DataUpdater(
            this.permissionService,
            this.env,
            userRegion
        );

        await dataUpdater.update();
    }

    private async updateMedia(userRegion: string) {
        const mediaUpdater = new MediaSyncUpdater(
            this.permissionService,
            this.env,
            userRegion,
            this.mediaListService
        );

        await mediaUpdater.getMedia(this.mediaProgress$);

        // Dummy progress for testing
        // await new Promise((resolve) => {
        //     let value = 0;
        //     const interval = setInterval(() => {
        //         value += 0.01;
        //         this.mediaProgress$.next(value);

        //         if (value >= 1) {
        //             clearInterval(interval);
        //             return resolve(true);
        //         }
        //     }, 100);
        // });
    }

    private async isDataOutdated(localDataLastModified: Date, userRegion: string): Promise<boolean> {
        try {
            const dataUpdater = new DataUpdater(
                this.permissionService,
                this.env,
                userRegion
            );
            const lastModifiedDates = await dataUpdater.getDataLastModifiedDates();
            // If any S3 data file has a last modified date that is newer than the local last modified date, data is outdated
            return lastModifiedDates.some((remoteDataLastModified) =>
                remoteDataLastModified > localDataLastModified
            );
        } catch (err) {
            console.log('Data update failed: ', err);
        }
    }

    private async isAppOutdated(): Promise<boolean> {
        try {
            return await isAppUpdateAvailable(this.env, this.platformService);
        } catch (err) {
            console.log('App update check failed: ', err);
        }
    }
}
