<ion-menu side="end" menuId="main-menu" content-id="main" [ngClass]="{'large-vertical-device' : this.platformService.isNativeDevice && this.platformService.isVertical}">
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-menu-toggle slot="end">
                <ion-buttons>
                    <ion-menu-button>
                        <ion-icon name="close"></ion-icon>
                    </ion-menu-button>
                </ion-buttons>
            </ion-menu-toggle>
        </ion-toolbar>
    </ion-header>

    <ion-content>
        <ion-item>
            <ion-label color="tertiary" [innerHTML]="region"></ion-label>
        </ion-item>
        <ion-list *ngFor="let link of links">
            <ion-menu-toggle>
                <ion-item [routerLink]="[link.route]">
                    <img *ngIf="link.icon" [src]="(link.icon.icon | file:'icon' | async)" slot="start" />
                    <ion-label [innerHtml]="link['title-translated'] || link.title"></ion-label>
                </ion-item>
            </ion-menu-toggle>
        </ion-list>
    </ion-content>

    <ion-footer>
        <ion-menu-toggle>
            <ion-item (click)="logOut()" class="btn-logout">
                <ion-icon name="log-out-outline" slot="start"></ion-icon>
                <ion-label [innerHtml]="'NAV_LOGOUT_TEXT' | translate">

                </ion-label>
            </ion-item>
        </ion-menu-toggle>
    </ion-footer>
</ion-menu>