import { Env, apiUrl, analyticsEndpointProd } from '../app/models';
import packageJson from '../../package.json';

export const environment = {
    production: true,
    name: Env.qa,
    apiUrl: apiUrl.staging,
    bapiEnv: 'staging',
    version: packageJson.version,
    analyticsEndpoint: analyticsEndpointProd
};
