/* eslint-disable @typescript-eslint/naming-convention */
import { CapacitorHttp } from '@capacitor/core';

import { PermissionService } from '../services/permission.service';
import { DataType } from './data';
import { componentsDirectory, dataDirectory, translationsDirectory } from '../models';
import { DataUpdateRunner } from './data-update-runner';
import { environment } from 'src/environments/environment';

export class DataUpdater {
    permissionService: PermissionService;
    env: string;
    region: string;

    readonly endpointPaths = {
        config: () => `config`,
        translations: (region: string) =>
            `${translationsDirectory}/translations-${region}`,
        components: (region: string) =>
            `${componentsDirectory}/component-data-content-${region}`,
    };

    constructor(
        permissionService: PermissionService,
        env: string,
        userRegion: string
    ) {
        this.permissionService = permissionService;
        this.env = env;
        this.region = userRegion;
    }

    async update(): Promise<void[]> {
        await this.permissionService.checkAndRequestPermission();

        const dataTypes: DataType[] = Object.keys(
            this.endpointPaths
        ) as DataType[];

        const promises = dataTypes.map((dataType: DataType) =>
            this.requestData(dataType)
        );

        return Promise.all(promises);
    }

    async getDataLastModifiedDates(): Promise<Date[]> {
        await this.permissionService.checkAndRequestPermission();

        const dataTypes: DataType[] = Object.keys(
            this.endpointPaths
        ) as DataType[];

        const promises = dataTypes.map((dataType: DataType) =>
            this.requestDataHeaders(dataType).then((res: Date) => new Date(res['Last-Modified']) || null)
        );

        return Promise.all(promises);
    }

    private async requestData(resource: DataType): Promise<void> {
        const endpoint = this.getDataEndpoint();
        const fileName = this.endpointPaths[resource](this.region);
        const filePath = this.buildFilePath(fileName);
        const url = `${endpoint}/${fileName}.json`;
        const noCacheHeaders = {
            'Cache-Control':
                'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Expires: '0',
        };

        try {
            const result = await CapacitorHttp.get({
                url,
                responseType: 'json',
                headers: noCacheHeaders,
            })
                .then((res) => res.data)
                .catch((e) => {
                    console.log('Http data request error : ', e);
                    throw e;
                });

            return DataUpdateRunner.writeFile(
                filePath,
                JSON.stringify(result),
                true
            );
        } catch (err) {
            console.log('Data update refresh error: ', err);
        }
    }

    private requestDataHeaders(resource: DataType): Promise<Date> {
        const endpoint = this.getDataEndpoint();
        const fileName = this.endpointPaths[resource](this.region);
        const url = `${endpoint}/${fileName}.json`;

        return CapacitorHttp.request({ method: 'HEAD', url })
            .then((res) => res.headers)
            .catch((e) => {
                console.log('Data update headers error : ', e);
                return null;
            });
    }

    private buildFilePath = (fileName: string, fileType: 'json' = 'json') => {
        const dataPath = dataDirectory.local;
        return `${dataPath}/${fileName}.${fileType}`;
    };

    private getDataEndpoint() {
        const apiGateway = `${dataDirectory.external}/${environment.bapiEnv}`;
        return apiGateway;
    }
}
