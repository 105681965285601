import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Directory, Filesystem, ReadFileResult } from '@capacitor/filesystem';

import { FileService } from 'src/app/services/file.service';
import { FileType } from './filemanager';
import { base64ToDataUrl } from 'src/app/helpers/utils';
import { PlatformService } from 'src/app/services/platform.service';

@Pipe({
    name: 'file',
})
export class FilePipe implements PipeTransform {
    constructor(
        private domSanitizer: DomSanitizer,
        private fileService: FileService,
        private platformService: PlatformService
    ) {}

    async transform(value: string, type?: FileType): Promise<any> {
        const fileType = type ? type : 'media';

        if (fileType === 'icon' && !this.platformService.isBrowser) {
            const iconFilePath = await this.fileService.getFilePath(value, 'icon', false);

            try {
                const iconDataResponse: ReadFileResult = await Filesystem.readFile({
                    path: iconFilePath,
                    directory: Directory.Data,
                });

                const iconDataUrl = base64ToDataUrl(iconDataResponse.data.toString(), {
                    ext: 'svg',
                    mimeType: 'image/svg+xml',
                });

                return Promise.resolve(this.getSafeUrl(iconDataUrl, fileType));
            } catch (e) {
                console.log('Could not get icon file ' + iconFilePath);
            }
        }

        const filePath = await this.fileService.getFilePath(value, fileType, true);

        return Promise.resolve(this.getSafeUrl(filePath, fileType));
    }

    getSafeUrl(filepath: string, filetype: FileType) {
        switch (filetype) {
            case 'media':
            case 'video':
                return this.domSanitizer.bypassSecurityTrustResourceUrl(
                    filepath
                );
            case 'data':
            case 'icon':
            case 'image':
            default:
                return this.domSanitizer.bypassSecurityTrustUrl(filepath);
        }
    }
}
