import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@jht/brand-translate';

import { FileModule } from './../../../shared/file/file.module';

import { HeaderComponent } from './header.component';

@NgModule({
    declarations: [HeaderComponent],
    imports: [CommonModule, FileModule, RouterModule, IonicModule, TranslateModule],
    exports: [HeaderComponent],
})
export class HeaderModule {}
