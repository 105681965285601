import { HttpClient } from '@angular/common/http';
import {
    Filesystem,
    Directory,
    StatResult,
    GetUriResult,
    DownloadFileOptions,
} from '@capacitor/filesystem';

import { Capacitor } from '@capacitor/core';

import { lastValueFrom } from 'rxjs';

import {
    base64ToDataUrl,
    blobToBase64DataUrl,
    getMimeFromFileExt,
} from './../../helpers/utils';
import { mediaDirectory, dataDirectory } from './../../models';

export type FileType = 'data' | 'icon' | 'image' | 'media' | 'video';
export type PlatformType = 'browser' | 'ios' | 'android' | 'native';

export interface FileManager {
    platform: string;

    getFilePath(filename: string, filetype: string, fullDevicePath: boolean);
    getIconPath(filename: string);
    getImagePath(filename: string);
    getMediaPath(filename: string);
    getVideoPath(filename: string);

    getFileAndPath?(filename: string);
    getImageDataUri?(urlOrFileName: string);
}

export abstract class BaseFileManager implements FileManager {
    abstract platform: any;

    constructor() {}

    abstract getFilePath(
        filename: string,
        filetype: string,
        fullDevicePath: boolean
    );
    abstract getIconPath(filename: string);
    abstract getImagePath(filename: string);
    abstract getMediaPath(filename: string);
    abstract getVideoPath(filename: string);
    abstract getImageDataUri(urlOrFileName: string);
}

export abstract class NativeBaseFileManager extends BaseFileManager {
    baseFilePath: string;

    constructor(private http: HttpClient) {
        super();
        this.getFileSystemBasePath();
    }

    getFileSystemBasePath() {
        Filesystem.getUri({
            path: '',
            directory: Directory.Data,
        }).then((path) => {
            let basePath = path.uri.replace(/\/\/$/, '/');

            // Android doesn't have ending slash
            if (!basePath.endsWith('/')) {
                basePath += '/';
            }
            this.baseFilePath = basePath.replace(/\/$/, '');
        });
    }

    async getFilePath(
        filename: string,
        filetype?: FileType,
        fullDevicePath?: boolean
    ): Promise<string> {
        const fileName = encodeURIComponent(filename);

        switch (filetype) {
            case 'data': {
                return await this.getDataPath(fileName);
            }
            case 'icon': {
                return await this.getIconPath(fileName, fullDevicePath);
            }
            case 'image': {
                return await this.getImagePath(fileName, fullDevicePath);
            }
            case 'media': {
                return await this.getMediaPath(fileName, fullDevicePath);
            }
            case 'video': {
                return await this.getVideoPath(fileName, fullDevicePath);
            }
            default:
                return await this.getMediaPath(fileName, fullDevicePath);
        }
    }

    async getDataPath(filename: string) {
        const path = [dataDirectory.local, filename].filter(Boolean).join('/');

        return await this.validateFilePathExistence(path);
    }

    async getIconPath(
        filename: string,
        fullDevicePath?: boolean
    ): Promise<string> {
        const path = [mediaDirectory.localMedia, filename]
            .filter(Boolean)
            .join('/');

        return await this.validateFilePathExistence(path, fullDevicePath);
    }

    async getImagePath(
        filename: string,
        fullDevicePath?: boolean
    ): Promise<string> {
        const path = [mediaDirectory.localImages, filename]
            .filter(Boolean)
            .join('/');

        return await this.validateFilePathExistence(path, fullDevicePath);
    }

    async getMediaPath(
        filename: string,
        fullDevicePath?: boolean
    ): Promise<string> {
        const path = [mediaDirectory.localMedia, filename]
            .filter(Boolean)
            .join('/');

        return await this.validateFilePathExistence(path, fullDevicePath);
    }

    async getVideoPath(
        filename: string,
        fullDevicePath?: boolean
    ): Promise<string> {
        const path = [mediaDirectory.localMedia, filename]
            .filter(Boolean)
            .join('/');

        return await this.validateFilePathExistence(path, fullDevicePath);
    }

    /**
     * Ensure file exists, fetching it if necessary, and then return the file path.
     * Assumes the file is the 'media' type.
     */
    async getFileAndPath(filename: string): Promise<StatResult> {
        const localFilePath = `${mediaDirectory.localMedia}/${filename}`;

        const nativeFile = {
            path: localFilePath,
            directory: Directory.Data,
        };

        const fileStats: StatResult = await Filesystem.stat(nativeFile).catch(
            (_) => null
        );

        const fileIsBroken: boolean =
            fileStats && fileStats.size === 0 ? true : false;

        if (fileIsBroken) {
            await Filesystem.deleteFile(nativeFile);
        }

        if (fileStats && !fileIsBroken) {
            return fileStats;
        }

        if (!fileStats) {
            const decodedUrl = decodeURIComponent(filename);
            const options: DownloadFileOptions = {
                url: decodedUrl,
                path: localFilePath,
            };
            return Filesystem.downloadFile(options)
                .then(() => {
                    this.getFileAndPath(filename);
                })
                .catch((_) => null);
        }
    }

    async getImageDataUri(filename: string): Promise<string> {
        const encodedFileName = encodeURIComponent(filename);
        const stat: StatResult = await this.getFileAndPath(encodedFileName);
        const fileExt = stat?.uri.split('.').pop();
        const mime = getMimeFromFileExt(fileExt);
        const imageFileBase64 = await Filesystem.readFile({
            path: `${mediaDirectory.localMedia}/${encodedFileName}`,
            directory: Directory.Data,
        })
            .then((res) => res.data)
            .catch((err) => {
                console.log(
                    'Error reading image from NativeBaseFileManager.getImageDataUri(): ',
                    err
                );
                return null;
            });
        return base64ToDataUrl(imageFileBase64, { mimeType: mime });
    }

    private async validateFilePathExistence(
        path: string,
        fullDevicePath?: boolean
    ): Promise<string> {
        const file = {
            path,
            directory: Directory.Data,
        };

        let getUriResult: GetUriResult = null;

        try {
            await Filesystem.stat(file);
            getUriResult = await Filesystem.getUri(file);
        } catch (e) {
            getUriResult = null;
        }

        if (getUriResult && getUriResult.uri) {
            if (fullDevicePath) {
                const uri = getUriResult.uri;
                return Capacitor.convertFileSrc(uri);
            } else {
                return path;
            }
        } else {
            throw new Error(`File doesn't exist`);
        }
    }
}

export class BrowserFileManager extends BaseFileManager {
    platform: PlatformType = 'browser';

    constructor(private http: HttpClient) {
        super();
    }

    async getFilePath(filename: string, filetype: FileType): Promise<string> {
        switch (filetype) {
            case 'data': {
                return this.getDataPath(filename);
            }
            case 'icon': {
                return this.getIconPath(filename);
            }
            case 'image': {
                return this.getImagePath(filename);
            }
            case 'media': {
                return this.getMediaPath(filename);
            }
            case 'video': {
                return this.getVideoPath(filename);
            }
            default:
                return this.getMediaPath(filename);
        }
    }

    getDataPath(filename: string) {
        return [dataDirectory.external, filename].filter(Boolean).join('/');
    }

    getIconPath(filename: string) {
        return [mediaDirectory.externalIcons, filename]
            .filter(Boolean)
            .join('/');
    }

    getImagePath(filename: string) {
        return [mediaDirectory.localImages, filename].filter(Boolean).join('/');
    }

    getMediaPath(filename: string) {
        return [mediaDirectory.externalMedia, filename]
            .filter(Boolean)
            .join('');
    }

    getVideoPath(filename: string) {
        return [mediaDirectory.externalMedia, filename]
            .filter(Boolean)
            .join('');
    }

    async getImageDataUri(filename: string): Promise<string> {
        const url = mediaDirectory.externalMedia + filename;
        return await lastValueFrom(
            this.http.get(url, { responseType: 'blob' })
        ).then((res) => blobToBase64DataUrl(res));
    }
}

export class IosFileManager extends NativeBaseFileManager {
    platform: PlatformType = 'ios';

    constructor(http: HttpClient) {
        super(http);
    }
}

export class AndroidFileManager extends NativeBaseFileManager {
    platform: PlatformType = 'android';

    constructor(http: HttpClient) {
        super(http);
    }
}
