import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { lastValueFrom, take } from 'rxjs';

import { DataUpdateRunner } from '../data/data-update-runner';
import { AuthenticationService } from '../services/authentication.service';
import { PlatformService } from '../services/platform.service';
import { ActiveUserService } from '../services/active-user.service';

@Injectable({
    providedIn: 'root',
})
export class LoginGuard  {
    constructor(
        private authService: AuthenticationService,
        private activeUserService: ActiveUserService,
        private router: Router,
        private dataUpdateRunner: DataUpdateRunner,
        private platformService: PlatformService
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return new Promise(async (resolve) => {
            const user = await lastValueFrom(this.activeUserService.currentUser$.pipe(take(1)));

            const isAuthenticated = await this.authService.isAuthenticated();
            if (isAuthenticated) {
                if (user) {
                    if (this.platformService.isNativeDevice) {
                        await this.dataUpdateRunner.next({
                            userProfile: await this.activeUserService.userProfile(),
                        });
                    }

                    this.router.navigate(['/home']);
                }
            }
            resolve(true);
        });
    }
}
