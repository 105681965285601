import { Injectable } from '@angular/core';
import { Filesystem, PermissionStatus } from '@capacitor/filesystem';

/**
 * Class for working with native file permissions
 */
@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    constructor() {}

    async checkAndRequestPermission(): Promise<void> {
        return Filesystem.checkPermissions()
            .then(async (result: PermissionStatus) => {
                const needsPrompt =
                    result.publicStorage === 'denied' ||
                    result.publicStorage === 'prompt';

                if (needsPrompt) {
                    const permissionStatus: PermissionStatus =
                        await Filesystem.requestPermissions();

                    if (permissionStatus.publicStorage !== 'granted') {
                        throw new Error(
                            'Access to files must be granted but was denied'
                        );
                    }
                }

                return;
            })
            .catch((err) => {
                console.log('Error on checking/requesting permissions: ', err);
                throw err;
            });
    }
}
