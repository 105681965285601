import { Injectable } from '@angular/core';

import { AlertController, AlertOptions } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private alertController: AlertController) {}

    async alert(config: AlertOptions) {
        const alertElement = await this.alertController.create(config);
        return alertElement.present();
    }

    error(
        message?: string,
        title?: string,
    ) {
        this.alert({header: title, message});
    }
}
