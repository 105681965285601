/* eslint-disable @typescript-eslint/naming-convention */
import { parseString } from 'xml2js';
import { gt } from 'semver';
import { CapacitorHttp } from '@capacitor/core';

import { environment } from 'src/environments/environment';
import { PlatformService } from '../services/platform.service';

export const isAppUpdateAvailable = async (env: string, platformService: PlatformService): Promise<boolean> => {
    const appDownloadUrl = 'https://mea-app.s3.us-east-2.amazonaws.com';
    const platform = platformService.isAndroid ? 'android' : 'ios';

    const appVersions = await getAppVersions(appDownloadUrl, env, platform);
    const latestVersion = appVersions.pop();

    /** Before merging to qa, remove hardcoded version **/
    return gt(latestVersion, environment.version);
};

export const getAppVersions = async (appDownloadUrl: string, env: string, platform: string): Promise<string[]> => {
    const url = `${appDownloadUrl}?list-type=2&prefix=${env}/${platform}`;

    const response = await CapacitorHttp.get({ url }).then((res) => res.data);

    let versions = null;

    parseString(response, (err, result) => {
      versions = result.ListBucketResult.Contents
        .map((content) => content.Key[0].replace(`${env}/${platform}/`, '').split('/')[0])
        .filter(version => /^[0-9]+.[0-9]+.[0-9]+$/.test(version))
        .sort();
    });

    return versions;
};
