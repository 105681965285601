import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SiteContent } from '../models';
import { DataService } from '../services/data.service';
import { DataRoutingService } from '../services/data-routing.service';

@Injectable({
    providedIn: 'root',
})
export class PageGuard  {
    constructor(private contentService: DataService, private router: Router) {}

    getValidPartsFromRoute(
        routingTree: Record<string, SiteContent>[],
        urlParts: string[]
    ): string {
        const validUrlParts = [];
        const makeValidUrlParts = (tree: Record<string, SiteContent>[]) => {
            urlParts.forEach((element, i) => {
                const isDuplicate = validUrlParts.find(
                    (items) => items.i === i
                );

                if (tree[element] && !isDuplicate) {
                    validUrlParts.push({ i, element });
                    makeValidUrlParts(tree[element]);
                }
            });

            return validUrlParts;
        };

        const url = makeValidUrlParts(routingTree).map(
            (urlParts) => urlParts.element
        );

        return `/${url.join('/')}`;
    }

    canActivateChild(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const urlParts = state.url.split('/').filter(Boolean);
        return this.contentService.getAllData().pipe(
            filter((data) => !!data.length),
            map((data) => {
                const routingTree = data.reduce(
                    DataRoutingService.createRoutingTree,
                    []
                );
                const validUrl = this.getValidPartsFromRoute(
                    routingTree,
                    urlParts
                );

                //todo: change to an identity check when CMS data is being used.
                if (!validUrl.includes(state.url)) {
                    this.router.navigate(['/404']);
                }

                return true;
            })
        );
    }
}
