/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    BehaviorSubject,
    filter,
    lastValueFrom,
    Observable,
    share,
    take,
    tap,
} from 'rxjs';
import { Directory, Encoding, Filesystem, ReadFileResult } from '@capacitor/filesystem';

import {
    componentsDirectory,
    Config,
    dataDirectory,
    globalFallbackRegLang,
    meaDefaultRegion,
    regionMap,
    SiteContent,
    TranslationData,
    translationsDirectory,
} from '../models';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { PlatformService } from './platform.service';
import { getNestedComponentData } from '../data/component-data';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    cache = {};
    entityIds: number[] = [];
    region: string;
    country: string;
    config: Config;
    fallbackLanguages: string[];
    regionLanguage: string;

    translationsData: TranslationData;
    translationsData$: BehaviorSubject<any> = new BehaviorSubject({});
    getSiteContentByType$: BehaviorSubject<SiteContent[]> = new BehaviorSubject(
        []
    );

    readonly awsS3BucketUrl = `${dataDirectory.external}/${environment.bapiEnv}`;

    private allData$: BehaviorSubject<SiteContent[]> = new BehaviorSubject([]);
    private navData$: BehaviorSubject<SiteContent[]> = new BehaviorSubject([]);

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService,
        private platformService: PlatformService,
    ) {}

    getAllData(): Observable<SiteContent[]> {
        return this.allData$.asObservable().pipe(
            filter((data) => !!data.length),
            take(1)
        );
    }

    getNavData(): Observable<SiteContent[]> {
        return this.navData$.asObservable().pipe(
            filter((data) => !!data.length),
            take(1)
        );
    }

    setAllData(data) {
        this.allData$.next(data.content);
        this.navData$.next(data.navigation);
    }

    async initSiteWideData(region: string): Promise<string[]> {
        this.region = region;
        const loadConfigResponse = await this.loadTranslationConfig();
        return await Promise.all([
            loadConfigResponse,
            this.loadTranslationsData(),
            this.loadComponentData(),
        ]);
    }

    loadTranslationConfig(): Promise<any> {
        return new Promise(async (resolve) => {
            try {
                const configResponse = await this.getFileData('config');

                this.setConfigProperties(configResponse);

                resolve('Config Data Loaded');
            } catch (e) {
                this.notificationService.error(
                    e.message,
                    'Could not fetch config.json'
                );
            }
        });
    }

    setConfigProperties(configResponse: Record<string, Config>): void {
        this.config = configResponse[this.region];

        this.country = this.config.country;
        const defaultLanguage = this.config.defaultPreferredLanguage;
        this.fallbackLanguages =
            this.config.languages[defaultLanguage].fallback;
    }

    async loadComponentDataByName(componentName: string): Promise<any> {
        const data = await this.getFileData(componentName);
        return data;
    }

    private async loadComponentData(): Promise<string> {
        const componentsDataFilePath = `${componentsDirectory}/component-data-content`;
        const componentConfigDataFilePath = `${componentsDirectory}/config`;

        try {
            const componentConfigData =  await this.getFileData(
                componentConfigDataFilePath
            );
            const data = await this.getFileData(
                componentsDataFilePath + '-' + this.region
            );
            if (this.fallbackLanguages.includes(globalFallbackRegLang)) {
                const fallbackData = await this.getFileData(
                    `${componentsDataFilePath}-${meaDefaultRegion}`
                );
                const componentList: string[] = Object.keys(data);
                componentList.forEach((componentName) => {
                    if (!data[componentName].length) {
                        data[componentName] = fallbackData[componentName];
                    }
                });
            }

            const nestedComponentData =
                getNestedComponentData(
                    data,
                    componentConfigData
                );

            this.setAllData(nestedComponentData);

            return 'Page Data Loaded';
        } catch (e) {
            this.notificationService.error(
                e.message,
                'Could not fetch ' + componentsDataFilePath + '.json'
            );
        }
    }

    private async combineFallbacks(
        translationsDataFilePath: string
    ): Promise<TranslationData> {
        const translationsByRegion = this.fallbackLanguages.map(
            async (regLang) => {
                const [lang, reg] = regLang.split('-');

                const translationData = await this.getFileData(
                    translationsDataFilePath + `-${regionMap(reg)}`
                );

                const translationValues = Array.isArray(translationData)
                    ? translationData[0]
                    : translationData;
                return translationValues;
            }
        );

        const translations: TranslationData[] = await Promise.all(
            translationsByRegion
        );

        return translations?.reduce((newObject, items) => {
            const keyName = Object.keys(items).toString();
            newObject[keyName] = Object.values(items)[0];
            return newObject;
        }, {} as TranslationData);
    }

    private async loadTranslationsData(): Promise<any> {
        const translationsDataFilePath = `${translationsDirectory}/translations`;

        try {
            this.translationsData = await this.combineFallbacks(
                translationsDataFilePath
            );
            this.translationsData$.next(this.translationsData);
            return 'Translation Data Loaded';
        } catch (e) {
            this.notificationService.error(
                e.message,
                'Could not fetch ' + translationsDataFilePath + '.json'
            );
        }
    }

    private async getFileData(fileName: string): Promise<any> {
        const file = fileName;

        try {
            if (this.cache[file]) {
                return Promise.resolve(this.cache[file]);
            }
            const noCacheHeaders = {
                'Cache-Control':
                    'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                Expires: '0',
            };
            return lastValueFrom(
                this.httpClient
                    .get(`${this.awsS3BucketUrl}/${file}.json`, {
                        headers: noCacheHeaders,
                    })
                    .pipe(
                        share(),
                        tap((data) => (this.cache[file] = data))
                    )
            );
        } catch (e) {
            if (this.platformService.isNativeDevice) {
                const read: ReadFileResult = await Filesystem.readFile({
                    path: `assets/data/${file}.json`,
                    directory: Directory.Data,
                    encoding: Encoding.UTF8,
                });
                return JSON.parse(read.data.toString());
            }
        }
    }
}
