import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@jht/brand-translate';
import { Subject } from 'rxjs';

export interface DataUpdateComponentOptions {
    message: string;
    dataType: 'data' | 'media';
    progress$?: Subject<number>;
}

@Component({
    selector: 'mea-data-update',
    templateUrl: './data-update.component.html',
    styleUrls: ['./data-update.component.scss'],
    standalone: true,
    imports: [CommonModule, IonicModule, TranslateModule]
})
export class DataUpdateComponent {
    @Input() options$ = new Subject<DataUpdateComponentOptions>();
}
