import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { AnalyticsEvent, AnalyticsService } from './analytics.service';
import { NavItem, SiteContent } from '../models';

@Injectable({
    providedIn: 'root',
})
export class DataRoutingService {
    navItems$: Observable<SiteContent[]> = new Observable();
    gridData$: Subject<any> = new Subject();
    params$: Observable<Params>;
    private routeParamsChangeSource$ = new BehaviorSubject<Params>({});


    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private analytics: AnalyticsService
    ) {
        this.params$ = this.routeParamsChangeSource$.asObservable();

        const route$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute)
        );

        const primaryRoute$ = route$.pipe(
            startWith(this.activatedRoute),
            map((route) => {
                let params = {};
                while (route.firstChild) {
                    params = {
                        ...params,
                        ...route.snapshot.params,
                    };

                    route = route.firstChild;
                }
                params = {
                    ...params,
                    ...route.snapshot.params,
                };
                return { route, params };
            }),
            filter((data) => data.route.outlet === 'primary')
        );

        primaryRoute$.subscribe((data) => {
            if (this.router.url !== '/') {
                const event: AnalyticsEvent = {
                    type: 'page-view',
                };

                if (data.route.snapshot.data.track) {
                    event.tags = [data.route.snapshot.data.track];
                }
                this.analytics.track(event);
            }


            this.routeParamsChangeSource$.next(data.params);
        });

    }

    static createRoutingTree(
        routes: [],
        items: NavItem
    ): Record<string, SiteContent>[] {
        const routeName = items.route;
        const childElements = items.children;

        if (!routes[routeName]) {
            routes[routeName] = {};
        }
        if (childElements) {
            childElements.reduce(
                DataRoutingService.createRoutingTree,
                routes[routeName]
            );
            return routes;
        }

        routes[routeName] = routeName;

        return routes;
    }

}
