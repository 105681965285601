import { NgModule } from '@angular/core';
import {
    ExtraOptions,
    PreloadAllModules,
    RouterModule,
    Routes,
} from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { PageGuard } from './guards/page.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./landing/landing.module').then((m) => m.LandingModule),
        canActivate: [LoginGuard],
    },
    {
        path: '404',
        loadChildren: () =>
            import('./page-not-found/page-not-found.module').then(
                (m) => m.PageNotFoundModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: ':category',
        loadChildren: () =>
            import('./page/page.module').then((m) => m.PageModule),
        canActivate: [AuthGuard],
        canActivateChild: [PageGuard],
    },
];

export const routingConfiguration: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
};
@NgModule({
    imports: [RouterModule.forRoot(routes, routingConfiguration)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
