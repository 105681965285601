import {
    Directory,
    DownloadFileOptions,
    FileInfo,
    Filesystem,
} from '@capacitor/filesystem';

import { BehaviorSubject } from 'rxjs';

import { mediaDirectory } from '../models';
import { PermissionService } from '../services/permission.service';
import { MediaListService } from './media-list.service';

interface MediaAsset {
    fileName: string;
    retries: number;
}

export class MediaSyncUpdater {
    permissionService: PermissionService;
    env: string;
    userRegion: string;

    private readonly downloadRetries = 2;

    constructor(
        permissionService: PermissionService,
        env: string,
        userRegion: string,
        private mediaListService: MediaListService
    ) {
        this.permissionService = permissionService;
        this.env = env;
        this.userRegion = userRegion;
    }

    async getMedia(mediaProgress$: BehaviorSubject<number>) {
        await this.permissionService.checkAndRequestPermission();

        const metadata: string[] = await this.mediaListService.getMediaListData(this.userRegion);
        const currentFiles = await this.loadCurrentMedia();

        // Create directory for media
        if (!currentFiles.length) {
            await Filesystem.mkdir({
                path: mediaDirectory.localMedia,
                directory: Directory.Data,
                recursive: true,
            }).catch(() => null);
        }

        const totalAssets: string[] = metadata.map((item) =>
            encodeURIComponent(item)
        );

        const allAssets = Array.from(new Set(totalAssets));

        const unusedFiles = [];
        currentFiles.forEach((file) => {
            if (!allAssets.includes(file.name)) {
                unusedFiles.push(file.name);
            }
        });

        const assetsToDownload: MediaAsset[] = allAssets
            .filter(
                (asset) => !currentFiles.find((file) => file.name === asset)
            )
            .map((asset) => ({
                fileName: asset,
                retries: 0,
            }));
        const totalAssetsCount = assetsToDownload.length;

        const concurrentRequests = 10;

        const updateProgress = () => {
            const progress = 1 - assetsToDownload.length / totalAssetsCount;
            mediaProgress$.next(progress);
        };

        await new Promise(async (resolve) => {
            const downloadWorker = (): Promise<void> => {
                if (assetsToDownload.length) {
                    const asset = assetsToDownload.pop();
                    updateProgress();

                    return this.mediaRequest(asset.fileName)
                        .then(() => null)
                        .catch(() => {
                            asset.retries += 1;

                            if (asset.retries <= this.downloadRetries) {
                                assetsToDownload.push(asset);
                                updateProgress();
                            }
                        })
                        .finally(() => downloadWorker());
                } else {
                    return Promise.resolve(null);
                }
            };

            const workers: Promise<void>[] = [];
            for (let i = 0; i < concurrentRequests; i++) {
                workers.push(downloadWorker());
            }

            await Promise.all(workers).then(() => {
                mediaProgress$.complete();
                return resolve(null);
            });
        });

        await Promise.all(
            unusedFiles.map((file: string) => this.removeFile(file))
        );
    }

    private async mediaRequest(file: string) {
        const localFilePath = `${mediaDirectory.localMedia}/${file}`;
        const options: DownloadFileOptions = {
            url: decodeURIComponent(file),
            responseType: 'blob',
            directory: Directory.Data,
            path: localFilePath
        }
        return await Filesystem.downloadFile(options).catch((err) => {
            throw err;
        });
    }

    private loadCurrentMedia(): Promise<string[] | FileInfo[] | any[]> {
        const path = mediaDirectory.localMedia;
        const directory = Directory.Data;

        return Filesystem.readdir({
            path,
            directory,
        })
            .then((result) => result.files)
            .catch((_) =>
                // Return empty file list
                []
            );
    }

    private removeFile(file: string): Promise<any> {
        const path = `${mediaDirectory.localMedia}/${file}`;

        return Filesystem.deleteFile({
            path,
            directory: Directory.Data,
        }).catch((err) => {
            console.log(`${err} error deleting file`);
        });
    }
}
