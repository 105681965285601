import { Preferences } from '@capacitor/preferences';

export interface StorageDriver {
  set(key: string, value: string): Promise<void>;
  get(key: string): Promise<string>;
  remove(key: string): Promise<void>;
}

/** Uses Capacitor's Preferences plugin */
export class CapacitorStorageDriver implements StorageDriver {
  set(key: string, value: string): Promise<void> {
      return Preferences.set({
          key,
          value,
      });
  }

  get(key: string): Promise<string> {
      return Preferences.get({
          key,
      }).then((result) => result.value);
  }

  remove(key: string): Promise<void> {
      return Preferences.remove({
          key
      });
  }
}
