import * as mimeTypes from 'mime/lite';
import mergeImages from 'merge-images-v2';

export const TEN_MEGABYTES = 10 * 1024 * 1024;

export const blobToBase64DataUrl = (blob: Blob): Promise<any> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = () => {
            console.log('Error coverting blob to base 64: ', reader.error);
            reject(reader.error);
        };
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

/**
 * Creates a base 64 attachment string for the email composer plugin
 *
 * @param b64
 * @param fileName Including file extension
 */
export const base64ToEmailComposerBase64Format = (
    b64: string,
    fileName: string
): string => {
    if (!fileName) {
        throw new Error('File name is required for email composer attachment');
    }

    // eslint-disable-next-line prefer-const
    let [_, base64Str] = b64.split(';');
    base64Str = base64Str.substring('base64,'.length);
    const attachment = `base64:${fileName}//${base64Str}`;
    return attachment;
};

export const base64ToDataUrl = (
    b64: string,
    options: { ext?: string; mimeType?: string }
): string => {
    let mime: string;

    if (options.ext) {
        mime = getMimeFromFileExt(options.ext);
    } else if (options.mimeType) {
        mime = options.mimeType;
    }

    const dataUrl = `data:${mime};base64,${b64}`;
    return dataUrl;
};

/**
 * Extract the file extension embedded in the data url string
 */
export const getFileExtFromDataUrl = (b64: string): string => {
    // eslint-disable-next-line prefer-const
    let [dataType, _] = b64.split(';');
    const mime = dataType.replace('data:', '');
    return fileExtFromMime(mime);
};

/**
 * Pass in a file extension and get the corresponding mime type
 */
// eslint-disable-next-line arrow-body-style
export const getMimeFromFileExt = (ext: string) => {
    return mimeTypes.getType(ext);
};

/**
 * Pass in a mime type and get the corresponding file extension
 */
export const fileExtFromMime = (mime: string): string =>
    mimeTypes.getExtension(mime);

/**
 * Merge multiple images into one
 */
export const combineImages = (images: string[]): Promise<string> =>  mergeImages([...images]);

