import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@jht/brand-translate';

import { FileModule } from './../../../shared/file/file.module';

import { MenuComponent } from './menu.component';


@NgModule({
    declarations: [MenuComponent],
    imports: [CommonModule, FileModule, RouterModule, IonicModule, TranslateModule],
    exports: [MenuComponent],
    providers: [],
})
export class MenuModule {}
