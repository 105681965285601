import { Injectable } from '@angular/core';

import { StorageDriver, CapacitorStorageDriver } from './storage-driver';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private driver: StorageDriver;

    constructor() {
        this.driver = new CapacitorStorageDriver();
    }

    public set(key: string, value: string): Promise<void> {
        return this.driver.set(key, value);
    }

    public get(key: string): Promise<string> {
        return this.driver.get(key);
    }

    public remove(key: string): Promise<void> {
        return this.driver.remove(key);
    }
}


