import slugify from 'slugify';

import { ColorConfigItem, SiteContent, mediaDirectory } from '../models';
import { environment } from 'src/environments/environment';

interface SiteData {
    navigation: any;
    content: any;
}

export const getNestedComponentData = (
    components: Record<string, any>[],
    componentConfigData: any
): SiteData => {
    const getNamedConfigItems = (componentConfigs: Record<string, any>[]) =>
        componentConfigs.reduce((newArray, configItem) => {
            newArray[configItem.id] = configItem;
            return newArray;
        }, []);
    const namedConfig = getNamedConfigItems(componentConfigData);
    const relationshipTree: any = createComponentRelationshipTree(namedConfig);
    const result = structureDataTree(components, relationshipTree);
    return result;
};

const structureDataTree = (
    components: Record<string, any>,
    relationships: Record<string, string[]>
) => {
    const componentRelationShips = relationships;
    const home = buildHomePageData(components);
    const navigation = components.navigation;
    const typeMap = {
        categories: 'category',
        channels: 'channel',
    };
    const contentLinkProperties = [
        'frame',
        'images-24',
        'products',
        'video',
        'brochure-file',
    ];
    const getContentLinkComponentItems = (current, parent, type, name) => {
        if (current.hasOwnProperty(type)) {
            buildContentLink(current, parent, name);
        }
        return current;
    };

    //1. loop through component data
    //2. Use keys (componentName) to get list of possible children (componentValue) for that key in component relationships
    //3. For each child in component-relationships, loop through it's corresponding items in component-data
    //4. If field-name matches key, loop through component data value and match component data title with the value of
    //field property
    const componentDataKeyValue: any[] = Object.entries(components);
    const restructuredComponentData: any = [];
    //step 1
    for (const [componentName, componentValue] of componentDataKeyValue) {
        //step 2
        if (componentRelationShips[componentName]) {
            // console.log(componentValue)
            //step 3
            componentRelationShips[componentName].forEach(
                (childKey: string) => {
                    //step 4
                    if (components[childKey]) {
                        components[childKey].forEach(
                            (componentItem: Record<string, any[]>) => {
                                if (componentName) {
                                    componentValue.forEach((parent) => {
                                        parent.componentName = componentName;
                                        parent.type = typeMap[componentName];
                                        parent.route = createRouteFromTitle(
                                            parent.title
                                        );
                                        const includesInObject =
                                            Array.isArray(
                                                componentItem[componentName]
                                            ) &&
                                            componentItem[componentName]?.find(
                                                (property) =>
                                                    property?.title ===
                                                    parent.title
                                            );

                                        if (
                                            componentItem[componentName] &&
                                            Array.isArray(
                                                componentItem[componentName]
                                            ) &&
                                            (componentItem[
                                                componentName
                                            ].includes(parent.title) ||
                                                includesInObject)
                                        ) {
                                            if (!parent.children) {
                                                parent.children = [];
                                            }

                                            getContentLinkComponentItems(
                                                componentItem,
                                                parent,
                                                'frame',
                                                'color-configurator'
                                            );

                                            getContentLinkComponentItems(
                                                componentItem,
                                                parent,
                                                'images-24',
                                                'three-sixty'
                                            );
                                            getContentLinkComponentItems(
                                                componentItem,
                                                parent,
                                                'products',
                                                'gallery'
                                            );
                                            getContentLinkComponentItems(
                                                componentItem,
                                                parent,
                                                'video',
                                                'video'
                                            );
                                            getContentLinkComponentItems(
                                                componentItem,
                                                parent,
                                                'brochure-file',
                                                'brochure'
                                            );

                                            let isContentLink: boolean;
                                            contentLinkProperties.forEach(
                                                (element) => {
                                                    if (
                                                        componentItem.hasOwnProperty(
                                                            element
                                                        )
                                                    ) {
                                                        isContentLink = true;
                                                    }
                                                }
                                            );

                                            if (!isContentLink) {
                                                parent.children.push(
                                                    componentItem
                                                );
                                            }
                                        }
                                    });
                                }
                            }
                        );
                    }
                }
            );
        }
        if (!restructuredComponentData[componentName]) {
            {
                restructuredComponentData[componentName] = {};
            }
        }
        restructuredComponentData[componentName] = componentValue;
    }

    const siteData = {} as SiteData;
    siteData.navigation = navigation;
    siteData.content = [...home, ...restructuredComponentData.categories];

    return siteData;
};

const buildContentLink = (
    componentItem: Record<string, any>,
    parentData: Record<string, any>,
    id: string
): SiteContent[] => {
    if (id === 'color-configurator') {
        const frameItems = JSON.parse(JSON.stringify(componentItem.frame));
        const mergedFrameUpholstery = frameItems.map(
            createFilePathFromTitle(
                componentItem.upholstery,
                componentItem.title
            )
        );
        componentItem.mergedFrameUpholstery = mergedFrameUpholstery;
    } else if (id === 'gallery') {
        componentItem.products.map(
            (product: { 'color-configurators': any }) => {
                if (product['color-configurators']?.title) {
                    const frameItems = JSON.parse(
                        JSON.stringify(product['color-configurators'].frame)
                    );
                    const mergedFrameUpholstery = frameItems.map(
                        createFilePathFromTitle(
                            product['color-configurators'].upholstery,
                            product['color-configurators'].title
                        )
                    );
                    product['color-configurators'].mergedFrameUpholstery =
                        mergedFrameUpholstery;
                }
            }
        );
    }

    const name = id.replace(/-/g, ' ').toUpperCase();
    const contentLinkObject: any = {};
    contentLinkObject.children = contentLinkObject.children || [];
    contentLinkObject.type = 'contentLink';
    contentLinkObject['background-image'] = parentData['background-image'];
    contentLinkObject.route = createRouteFromTitle(id);

    const titleKey = `GLOBAL_${name.replace(' ', '-')}_TEXT`;
    contentLinkObject.title = titleKey.toUpperCase();

    componentItem.route = createRouteFromTitle(componentItem.title);

    contentLinkObject.children.push(componentItem);

    const indexOfChild = parentData.children.findIndex(
        (items) => items.route === id
    );

    if (indexOfChild !== -1) {
        parentData.children[indexOfChild].children.push(componentItem);
    } else {
        parentData.children.push(contentLinkObject);
    }

    parentData.route = createRouteFromTitle(parentData.title);
    return parentData.children;
};

export const createFilePathFromTitle =
    (
        upholstery: ColorConfigItem[],
        configTitle: string
    ): ((frameItem: ColorConfigItem) => ColorConfigItem) =>
    (frameItem: ColorConfigItem) => {
        if (upholstery.length) {
            delete frameItem['asset-image'];
            frameItem.upholstery = JSON.parse(JSON.stringify(upholstery)).map(
                (upholsteryItem: ColorConfigItem) => {
                    const mergedTitle = buildMergedImageTitle(
                        configTitle,
                        frameItem,
                        upholsteryItem
                    );
                    upholsteryItem['asset-image'] = buildMergedUrl(
                        mergedTitle,
                        environment.bapiEnv
                    );
                    return upholsteryItem;
                }
            );
        } else {
            const mergedTitle = buildMergedImageTitle(
                configTitle,
                frameItem,
                null
            );
            frameItem['asset-image'] = buildMergedUrl(mergedTitle,  environment.bapiEnv);
        }
        return frameItem;
    };

const buildMergedUrl = (mergedTitle: string, env: string): string =>
    `${mediaDirectory.externalCombined}${env}/${mergedTitle}.png`;

const buildMergedImageTitle = (
    itemTitle: string,
    frame: ColorConfigItem,
    upholstery: ColorConfigItem
): string => {
    // Replace unwanted characters for file names and encode for good measure
    const format = (str) => {
        str = str
            ?.toLowerCase()
            .replace(/ /g, '_')
            .replace(/\//g, '-')
            .replace(/\(|\)/g, '');
        return encodeURIComponent(str);
    };

    const equipment = itemTitle;

    let title = `${format(equipment)}_`;
    title += `${format(frame['color-title'])}_frame`;

    if (upholstery) {
        title += `_${format(upholstery['color-title'])}_upholstery`;
    }

    return title;
};

const buildHomePageData = (
    componentData: Record<string, any>
): SiteContent[] => {
    const categories = componentData.categories;

    componentData.homepage = [
        {
            title: 'home',
            headline: 'HOME_HEADLINE_TEXT',
            ['background-image']: 'HOME_BACKGROUND-IMAGE_URL',
        },
    ];
    const home = componentData.homepage.map((data: any) => {
        data.children = categories.map((child: any) => {
            const homeContent = {} as { route: string };
            const childData: [string, any][] = Object.entries(child);
            for (const [key, val] of childData) {
                if (key !== 'children') {
                    homeContent[key] = val;
                }

                if (key === 'title') {
                    homeContent.route = '../' + createRouteFromTitle(val);
                }
            }
            return homeContent;
        });
        data.type = data.route = data.title;
        return data;
    });
    return home;
};

const createRouteFromTitle = (title: string): string =>
    slugify(title)
        .toLowerCase()
        .replace(/(\()?(\))?/g, '');

const createComponentRelationshipTree = (
    namedConfItems: Record<string, { fields: [] }>
): Record<string, Record<string, string[]>> => {
    const data = namedConfItems;
    const entries: [string, { fields: [] }][] = Object.entries(data);
    const keyedFields = {};
    for (const [key, val] of entries) {
        keyedFields[key] = val.fields.map(
            ({ name, type, relationalComponent }) => ({
                name,
                type,
                relationalComponent,
            })
        );
    }
    const configFields: [string, []][] = Object.entries(keyedFields);
    const nestedStructure = {};

    for (const [componentName, val] of configFields) {
        const parents = val
            .filter(
                (fields: { type: string }) =>
                    fields.type === 'relational-component'
            )
            .map(
                (fields: { relationalComponent: string }) =>
                    fields.relationalComponent
            );
        parents.forEach((parentName) => {
            if (!nestedStructure[parentName]) {
                nestedStructure[parentName] = [];
            }
            nestedStructure[parentName].push(componentName);
        });
    }
    return nestedStructure;
};
