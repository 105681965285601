import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { environment } from '../../environments/environment';
import {
    componentsDirectory,
    dataDirectory,
    translationsDirectory,
} from '../models';
import { createFilePathFromTitle } from './component-data';

@Injectable({
    providedIn: 'root',
})
export class MediaListService {
    env: string;
    mediaListData: string[];

    constructor(private http: HttpClient) {
        this.env = environment.bapiEnv;
    }

    async getMediaListData(userRegion: string): Promise<string[]> {
        const componentDataEndpoint = `${dataDirectory.external}/${this.env}/${componentsDirectory}/component-data-content-${userRegion}.json`;
        const translationDataEndpoint = `${dataDirectory.external}/${this.env}/${translationsDirectory}/translations-${userRegion}.json`;
        const componentData: Record<string, any> = await lastValueFrom(
            this.http.get(componentDataEndpoint)
        );
        const translationData: Record<string, never>[] = (await lastValueFrom(
            this.http.get(translationDataEndpoint)
        )) as Record<string, never>[];
        const mediaFiles: string[] = await this.createMediaList(
            componentData,
            translationData
        );
        return mediaFiles;
    }

    private async createMediaList(
        componentData: Record<string, string[]>,
        translationdata: Record<string, never>[]
    ): Promise<string[]> {
        const componentValues: any = Object.values(componentData);
        const flattenedComponentValues = componentValues.flat();
        const combinedArrays: any = [
            ...flattenedComponentValues,
            translationdata,
        ];

        const data: string[] = combinedArrays
            .map((items: Record<string, any>) => {
                const results = this.getImagesFromData(items);
                return results;
            })
            .filter(Boolean)
            .flat();
        const uniqueList: string[] = [...new Set(data)];
        return uniqueList;
    }

    private getImagesFromData(item: any): string[] {
        const images: any[] = [];
        if (item.frame || item.upholstery) {
            const data = item.frame.map(
                createFilePathFromTitle(item.upholstery, item.title)
            );

            const frame = data?.map((colorConfigItem) => {
                if (colorConfigItem.upholstery) {
                    return colorConfigItem.upholstery.map(
                        (uphosteryItem) => uphosteryItem['asset-image']
                    );
                } else {
                    return colorConfigItem['asset-image'];
                }
            });
            images.push(...frame.flat());
        } else {
            Object.values(item).forEach((val: any) => {
                if (!val) {
                    return;
                }

                if (
                    typeof val === 'string' &&
                    val.includes('jhtassets.com') &&
                    !val.includes('href=')
                ) {
                    images.push(val);
                } else if (Array.isArray(val)) {
                    this.getImagesFromData(val);
                } else if (typeof val === 'object') {
                    this.getImagesFromData(Object.values(val));
                }
            });
        }

        return images;
    }
}
