import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderModule } from './header/header.module';
import { MenuModule } from './menu/menu.module';

const COMPONENTS = [];

const MODULES = [CommonModule, HeaderModule, MenuModule, RouterModule];

const SERVICES = [];

@NgModule({
    declarations: [COMPONENTS],
    imports: [MODULES],
    exports: [COMPONENTS, MODULES],
    providers: [SERVICES],
})
export class CoreComponentsModule {}
