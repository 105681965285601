import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { TranslateModule, TranslateService } from '@jht/brand-translate';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DataService } from './services/data.service';
import { SharedModule } from './shared/shared.module';
import { ActiveUserService } from './services/active-user.service';
import { AuthenticationService } from './services/authentication.service';
import { APP_STATE_KEYS, meaDefaultRegion } from './models';
import { StateService } from './services/state.service';
import { environment } from 'src/environments/environment';


export const setupTranslateConfig = (dataService: DataService) => {
    const fallbackLanguages = dataService.fallbackLanguages;
    return {
        siteConfig: {
            fallbackLanguages,
            regionLanguage: fallbackLanguages[0],
        },
        translations: {
            dictionary: dataService?.translationsData,
        },
    };
};

export const dataStartupFactory =
    (
        authenticationService: AuthenticationService,
        activeUserService: ActiveUserService,
        dataService: DataService
    ) =>
        () =>
            new Promise(async (resolve) => {
                const auth = await authenticationService.isAuthenticated();
                const region = auth
                    ? activeUserService.currentUserValue.user_metadata.region
                    : meaDefaultRegion;
                resolve(dataService.initSiteWideData(region));
            });

/** Reset the APP_IS_OUTDATED state after a new version installation */
export const verifyAppVersion = (
    stateService: StateService
) => (): Promise<void> => new Promise((resolve) => {
    stateService.get(APP_STATE_KEYS.APP_LAST_VERSION).then((lastAppVersion) => {
        const currentVersion = environment.version;

        if (lastAppVersion !== currentVersion) {
            Promise.all([
                stateService.set(APP_STATE_KEYS.APP_LAST_VERSION, currentVersion),
                stateService.set(APP_STATE_KEYS.APP_IS_OUTDATED, 'false')
            ]).then(() => resolve());
        } else {
            resolve();
        }
    });
});

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CoreModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        SharedModule,
        TranslateModule,
    ],
    providers: [
        TranslateService,
        {
            provide: 'translateConfig',
            useFactory: setupTranslateConfig,
            deps: [DataService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: dataStartupFactory,
            deps: [AuthenticationService, ActiveUserService, DataService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: verifyAppVersion,
            deps: [StateService],
            multi: true
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        EmailComposer,
        BackgroundMode
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }
