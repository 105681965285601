import { Component } from '@angular/core';

@Component({
    selector: 'mea-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor() {}
}
