import { Injectable, NgZone } from '@angular/core';

import { ConnectionStatus, Network } from '@capacitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@jht/brand-translate';

import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkStatusService {
    /**
     * Subscribe to these observables using `distinctUntilChanged()`
     * rxjs operator to return the value only when needed
     */
    connectionStatus$: Observable<ConnectionStatus>; // yields complete connection obj
    isOnline$: Observable<boolean>; // yields only connection status
    isOnline: boolean;

    private status$ = new BehaviorSubject<ConnectionStatus>(null);
    private connected$ = new BehaviorSubject<boolean>(false);

    constructor(
        private notificationService: NotificationService,
        private ngZone: NgZone,
        private translationService: TranslateService
    ) {
        this.getInitialNetworkStatus();

        Network.addListener('networkStatusChange', (status) => {
            this.ngZone.run(() => {
                this.status$.next(status);
                this.connected$.next(status.connected);
                this.isOnline = status.connected;
            });
        });

        this.connectionStatus$ = this.status$.asObservable();
        this.isOnline$ = this.connected$.asObservable();
    }

    getInitialNetworkStatus = async () => {
        const initialStatus = await Network.getStatus();
        this.status$.next(initialStatus);
        this.connected$.next(initialStatus.connected);
        this.isOnline = initialStatus.connected;
    };

    requireConnectionFactory(cb: (...args) => void) {
        return (...args) => {
            if (this.isOnline) {
                return cb(...args);
            } else {
                this.notificationService.alert({
                    header: this.translationService.instant('LINKS_NETWORK-MESSAGE_TITLE_TEXT'),
                    message: this.translationService.instant('LINKS_NETWORK-MESSAGE_COPY_TEXT'),
                    buttons: [
                        {
                            text: this.translationService.instant('LINKS_NETWORK-MESSAGE_CONFIRM_TEXT'),
                            role: 'confirm',
                        },
                    ],
                });
            }
        };
    }
}
