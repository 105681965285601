import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@jht/brand-translate';

import { PageDescriptionCardComponent } from './page-description-card.component';

@NgModule({
    declarations: [PageDescriptionCardComponent],
    imports: [CommonModule, RouterModule, IonicModule, TranslateModule],
    exports: [PageDescriptionCardComponent],
})
export class PageDescriptionCardModule {}
