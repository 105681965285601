import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StatResult } from '@capacitor/filesystem';

import {
    AndroidFileManager,
    BrowserFileManager,
    FileManager,
    FileType,
    IosFileManager,
} from './../shared/file/filemanager';
import { PlatformService } from './platform.service';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    fileManager: FileManager;
    userRegion: string;

    constructor(
        protected http: HttpClient,
        private platformService: PlatformService,
        dataService: DataService
    ) {
        this.setFileManager();
        this.userRegion = dataService.region;
    }

    setFileManager() {
        if (this.platformService.isAndroid) {
            this.fileManager = new AndroidFileManager(this.http);
        } else if (this.platformService.isIos) {
            this.fileManager = new IosFileManager(this.http);
        } else if (this.platformService.isBrowser) {
            this.fileManager = new BrowserFileManager(this.http);
        }
    }

    async getFilePath(
        filename: string,
        filetype: FileType,
        fullDevicePath?: boolean
    ): Promise<string> {
        try {
            return await this.fileManager.getFilePath(
                filename,
                filetype,
                fullDevicePath
            );
        } catch (e) {
            const browserFileManager = new BrowserFileManager(this.http);
            return browserFileManager.getFilePath(filename, filetype);
        }
    }

    async getFileAndPath(file: string): Promise<StatResult> {
        return this.fileManager.getFileAndPath(file);
    }

    async loadImageDataUrl(urlOrFilename: string): Promise<string> {
        return this.fileManager.getImageDataUri(urlOrFilename);
    }
}
