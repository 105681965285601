/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_STATE_KEYS, StateEntry } from '../models';

/**
 * Service for saving any user defined preferences and state of the app
 */
@Injectable({
    providedIn: 'root',
})
export class StateService {
    stateChange$: Observable<StateEntry>;
    private stateChangeSource$ = new BehaviorSubject<StateEntry>(null);

    constructor() {
        this.stateChange$ = this.stateChangeSource$.asObservable();
    }

    set(key: string, value: string): Promise<void> {
        return Preferences.set({
            key,
            value,
        }).then(() => {
            this.stateChangeSource$.next({ key, value });
        });
    }

    get(key: string): Promise<string | boolean> {
        return Preferences.get({
            key,
        }).then((result) => result.value);
    }

    resetDataState(): Promise<void[]> {
      return Promise.all([
        this.set(APP_STATE_KEYS.DATA_SYNC_STARTED, 'false'),
        this.set(APP_STATE_KEYS.DATA_SYNC_CONTENT_COMPLETED, 'false'),
        this.set(APP_STATE_KEYS.DATA_SYNC_COMPLETED, 'false'),
      ]);
    }
}
