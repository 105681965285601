import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@jht/brand-translate';
import { NavigationCardModule } from './components/navigation-card/navigation-card.module';
import { PageDescriptionCardModule } from './components/page-description-card/page-description-card.module';

const COMPONENTS = [];

const MODULES = [
    CommonModule,
    NavigationCardModule,
    PageDescriptionCardModule,
    RouterModule,
    TranslateModule,
];

const SERVICES = [];

@NgModule({
    declarations: [],
    imports: [MODULES],
    exports: [MODULES],
})
export class SharedModule {}
